import { type SVGProps, forwardRef } from 'react'

export const Remove = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      ref={ref}
      {...props}
    >
      <circle cx={10} cy={10} r={9} fill="currentColor" fillOpacity={0.06} />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.3}
        d="M7.5 7.5 13 13m-5.5 0L13 7.5"
      />
    </svg>
  )
)

Remove.displayName = 'Remove'
