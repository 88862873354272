import type { HTMLAttributes } from 'react'

import { cn, cnva } from '~/utils/cn'

import { Check as CheckIcon, Alert as AlertIcon, Remove } from '../Icons'

const alertStyle = cnva(
  'flex w-full items-start justify-between gap-2 rounded-md p-3 text-xs',
  {
    variants: {
      type: {
        info: 'bg-pop-up-bg text-pop-up-fg backdrop-blur-2xl light:bg-pop-up-bg/85 overall:bg-pop-up-bg/85 dark:bg-pop-up-bg/40',
        info_over_media: 'bg-under/64 text-highlight backdrop-blur-2xl',
        success:
          'bg-over text-foreground ring-1 ring-positive/40 overall:bg-over/20 overall:text-foreground dark:bg-over/12',
        error:
          'bg-over text-foreground ring-1 ring-destructive/24 overall:bg-over/20 overall:text-foreground dark:bg-over/12',
      },
    },
    defaultVariants: {
      type: 'info',
    },
  }
)

type CommonProps = HTMLAttributes<HTMLDivElement> & {
  onClose?: () => void
}

type InformativeAlert = CommonProps & {
  type?: 'info' | 'info_over_media'
  description: string
}

type SuccessAlert = CommonProps & {
  type: 'success'
  title: string
  description?: string
}

type ErrorAlert = CommonProps & {
  type: 'error'
  title: string
  description?: string
}

type AlertProps = InformativeAlert | ErrorAlert | SuccessAlert

function Alert(props: InformativeAlert): JSX.Element
function Alert(props: SuccessAlert): JSX.Element
function Alert(props: ErrorAlert): JSX.Element
function Alert({
  className,
  type,
  title,
  description,
  onClose,
  children,
  ...props
}: AlertProps) {
  return (
    <div role="alert" className={alertStyle({ type, className })} {...props}>
      <div className="flex flex-col gap-1.5">
        {type === 'error' && (
          <div className="flex items-center gap-2">
            <AlertIcon
              className="shrink-0 text-destructive"
              width="18px"
              height="18px"
            />
            <span>{title}</span>
          </div>
        )}
        {type === 'success' && (
          <div className="flex items-center gap-2">
            <CheckIcon
              className="shrink-0 text-positive"
              width="18px"
              height="18px"
            />
            <span>{title}</span>
          </div>
        )}
        {description && (
          <p className={cn(type === 'error' && 'opacity-64')}>{description}</p>
        )}
      </div>
      {onClose && (
        <button className="w-4.4 h-4.5 rounded-full" onClick={() => onClose()}>
          <Remove className="shrink-0" width="18px" height="18px" />
        </button>
      )}
    </div>
  )
}

export { Alert }
