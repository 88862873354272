import {
  Form as RemixForm,
  useActionData,
  useSubmit,
  useNavigation,
} from '@remix-run/react'
import { createForm } from 'remix-forms'

import FieldErrorWrapper from '~/elements/FieldErrorWrapper'
import FormButton from '~/elements/FormButton'
import FormErrorWrapper from '~/elements/FormErrorWrapper'
import Input from '~/elements/Input'

const RemixFormsForm = createForm({
  component: RemixForm,
  useNavigation,
  useSubmit,
  useActionData,
})

const DefaultRemixFormsForm: typeof RemixFormsForm = (props) => {
  const customProps = {
    inputComponent: Input,
    buttonComponent: FormButton,
    errorComponent: FieldErrorWrapper,
    globalErrorsComponent: FormErrorWrapper,
    ...props,
  }
  return RemixFormsForm(customProps)
}

export { DefaultRemixFormsForm as Form, RemixForm }
