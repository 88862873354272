import type { ReactNode } from 'react'
import React from 'react'

export default function FormErrorWrapper({
  role,
  children,
}: {
  role?: string
  children?: ReactNode
}) {
  return (
    <span role={role} className="bg-red-100">
      {children}
    </span>
  )
}
