import type { VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'

import { cnva } from '~/utils/cn'

export const INPUT_DEFAULT_SIZE: 'sm' | 'lg' = 'sm'

export const styles = cnva(
  [
    'relative w-full rounded-md',
    'border border-pop-up-fg/12',
    'bg-under/2 text-pop-up-fg dark:bg-under/12',
    'placeholder:text-pop-up-fg/64',
    'disabled:opacity-48',
    'autofill:bg-under/2 autofill:text-pop-up-fg autofill:dark:bg-under/12',
  ],
  {
    variants: {
      size: {
        sm: 'h-9 py-2 pl-[calc(var(--input-pl,0px)+theme(spacing[2]))] pr-[calc(var(--input-pr,0px)+theme(spacing[2]))] text-sm md:text-xs',
        lg: 'h-12 py-3.5 pl-[calc(var(--input-pl,0px)+theme(spacing[3.5]))] pr-[calc(var(--input-pr,0px)+theme(spacing[3.5]))] text-sm',
      },
      variant: {
        default:
          'border border-border/12 bg-under/2 text-foreground placeholder:text-foreground/64 dark:bg-under/12',
        'over-pop-up':
          'border border-pop-up-fg/12 bg-under/2 text-pop-up-fg placeholder:text-pop-up-fg/64 dark:bg-under/12',
      },
    },
    defaultVariants: {
      size: INPUT_DEFAULT_SIZE,
      variant: 'default',
    },
  }
)

export type InputSize = NonNullable<VariantProps<typeof styles>['size']>
export type InputVariant = NonNullable<VariantProps<typeof styles>['variant']>

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: InputSize
  variant?: InputVariant
  disabled?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, size, variant, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={styles({ size, variant, className })}
        {...props}
      />
    )
  }
)

Input.displayName = 'Input'
