import type { ReactNode } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function FieldErrorWrapper({
  children,
}: {
  children?: ReactNode
}) {
  const { t } = useTranslation()

  let errorKeys
  if (typeof children === 'string') {
    errorKeys = [children]
  } else if (
    Array.isArray(children) &&
    children.every((child) => typeof child === 'string')
  ) {
    errorKeys = children
  }

  return (
    <span className="text-red-500">{errorKeys ? t(errorKeys) : children}</span>
  )
}
