import { type SVGProps, forwardRef } from 'react'

export const Alert = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      ref={ref}
      {...props}
    >
      <circle
        cx={10.007}
        cy={10.003}
        r={8.35}
        stroke="currentColor"
        strokeWidth={1.3}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.3}
        d="M10 5.664v5.017"
      />
      <circle
        cx={9.998}
        cy={13.514}
        r={0.931}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
    </svg>
  )
)

Alert.displayName = 'Alert'
