import type { ButtonHTMLAttributes } from 'react'
import { useFormState } from 'react-hook-form'

import Button from './Button'

export default function FormButton(
  props: ButtonHTMLAttributes<HTMLButtonElement>
) {
  const { isDirty } = useFormState()

  return <Button {...props} disabled={props.disabled || !isDirty} />
}
