import React from 'react'

const Input = React.forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements['input']
>(({ type = 'text', ...props }, ref) => {
  const { className, ...otherProps } = props
  return (
    <input
      ref={ref}
      className={`peer-focus:text-sky focus:border-sky-300 rounded peer block min-h-[auto] border-2 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none ${className}`}
      type={type}
      {...otherProps}
    />
  )
})

Input.displayName = 'Input'
export default Input
